"use client";

import React from "react";

import { IconExit, LinkVariant, Link } from "@jasperlabs/jux-next";
import { useSession } from "contexts/session";
import AppHeader, {
	ContactUs as HeaderContactUs,
} from "components/layout/AppHeader";
import { TenantLogoLink } from "components/organisms/navigation";

const LogOutButton = () => {
	const { hasAuthenticatedSession, removeSession } = useSession();

	if (!hasAuthenticatedSession) {
		return <HeaderContactUs />;
	}

	return (
		<Link
			as="button"
			onClick={removeSession}
			variant={LinkVariant.InvertedPrimary}
			icon={<IconExit />}
			className="text-subheading-sm text-link-nav"
		>
			Log out
		</Link>
	);
};

function AppHeaderErrorOrNotFound() {
	return (
		<AppHeader>
			<AppHeader.Layout left={<TenantLogoLink />} right={<LogOutButton />} />
		</AppHeader>
	);
}

export default AppHeaderErrorOrNotFound;

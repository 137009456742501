import React from "react";

import { LinkVariant, Hidden, Breakpoint } from "@jasperlabs/jux-next";

import ContactUsClick from "../../../tenants/components/TenantSupportLink";

const ContactUs = () => (
	<span className="text-subheading-sm text-primary-200">
		<Hidden to={Breakpoint.XS}>Need help? </Hidden>
		<ContactUsClick variant={LinkVariant.InvertedPrimary}>
			Contact us
		</ContactUsClick>
	</span>
);

export default ContactUs;

"use client";

import React, { Fragment } from "react";

import { Button, ButtonVariant, ButtonSize } from "@jasperlabs/jux-next";

import AppHeaderErrorOrNotFound from "components/layout/AppHeader/AppHeaderErrorOrNotFound";
import ContactUs from "../tenants/components/TenantSupportLink";

export default function Error({ reset }: { reset: () => void }) {
	return (
		<Fragment>
			<AppHeaderErrorOrNotFound />
			<section className="h-full min-h-screen bg-white pt-11 md:pt-12">
				<div className="max-w-container-lg mx-auto w-full">
					<h1 className="text-heading-sm mx-auto mb-7 max-w-[260px] text-center md:mb-8 md:max-w-[280px] lg:max-w-none">
						An unexpected error has occurred
					</h1>
					<p className="text-body-xl text-color-body mx-auto mb-7 max-w-[280px] text-center md:mb-8 md:max-w-[360px]">
						Please refresh the page and try again or{" "}
						<ContactUs className="text-inherit">contact us</ContactUs> for
						further assistance.
					</p>
					<div className="text-center">
						<Button
							variant={ButtonVariant.OutlinePrimary}
							size={ButtonSize.Small}
							className="mx-auto"
							onClick={reset}
						>
							Refresh this page
						</Button>
					</div>
				</div>
			</section>
		</Fragment>
	);
}
